
function App() {
  return (
    <div>
      <header style={{marginLeft: 10, marginTop: 10}}>
        Vince Gandolfo
      </header>
      <ul>
        <li>twitter: <a href="https://www.twitter.com/vince_gando">@vince_gando</a></li>
        <li>linkedin: <a href="https://www.linkedin.com/in/vincegando">vincegando</a></li>
      </ul>
    </div>
  );
}

export default App;
